import { useEffect, useMemo, useState } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import MetricView from '../components/metrics';
import LogsView from '../components/logs';
import { useLocation, useNavigate } from 'react-router-dom';

const BASE_URL = 'http://127.0.0.1:5000/api';

const Container = () => {
  const navigate = useNavigate();

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let queryParams = useQuery();

  const [gcpLocation, setGcpLocation] = useState(null);
  const [issueData, setIssueData] = useState({
    summary: null,
    details: null,
    relevant_files: null,
  });
  const [failureMessage, setFailureMessage] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [loading, setLoading] = useState(false);

  const processIssueData = (data) => {
    setIssueData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  useEffect(() => {
    if (queryParams.get('gcp_location')) {
      setGcpLocation(queryParams.get('gcp_location'));
    }
  }, [queryParams]);

  const fetchIssueData = async () => {
    if (!gcpLocation) {
      return;
    }
    let gcpLocationEncoded = encodeURIComponent(gcpLocation);
    const url = `${BASE_URL}/admin/issue_viewer?gcp_location=${gcpLocationEncoded}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options);
    if (response.ok) {
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchIssueData();
    const id = setInterval(() => {
      fetchIssueData();
    }, 5000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [gcpLocation]);

  useEffect(() => {
    if (issueData.relevant_files && issueData.relevant_files.length > 0) {
      clearInterval(intervalId);
    }
  }, [issueData.relevant_files, intervalId]);

  const handleResolveIncident = async () => {
    setLoading(true);
    try {
      const parts = gcpLocation.split('/');
      const locator = `${parts[parts.length - 3]}/${parts[parts.length - 2]}`;
      
      console.log("url: ", `${BASE_URL}/sandbox/incidents/resolve`);
      console.log("locator", locator);
      console.log("system_name", issueData.system.name);
      const response = await axios.post(`${BASE_URL}/sandbox/incidents/resolve`, {
        locator: locator,
        system_name: issueData.system.name,
      });

      const { id } = response.data;

      const pollGenerationStatus = async (taskId) => {
        try {
          const statusResponse = await axios.get(`${BASE_URL}/sandbox/generation_tasks/${taskId}`);
          const { analysis_status, locator } = statusResponse.data;

          if (analysis_status !== 'NOT_STARTED') {
            const systemId = encodeURIComponent(issueData.system.name);
            const gcp_location = `datasets/v3/${systemId}/${locator}/issue.json`;

            navigate(`/issue_analysis_viewer?gcp_location=${gcp_location}`);
          } else {
            setTimeout(() => pollGenerationStatus(taskId), 2000);
          }
        } catch (error) {
          console.error('Error polling generation status:', error);
        }
      };

      pollGenerationStatus(id);
    } catch (error) {
      console.error('Error resolving incident:', error);
      setLoading(false);
    }
  };

  const relevantDataByServices = (data) => {
    if (!data) {
      return [];
    }

    let relevantData = new Map();
    data['system']['services'].forEach((service) => {
      data['relevant_files'].forEach((fileName) => {
        if (fileName.startsWith(service.name)) {
          if (!relevantData.has(service.name)) {
            relevantData.set(service.name, []);
          }
          relevantData.get(service.name).push(fileName);
        }
      });
    });
    return Array.from(relevantData, ([service, files]) => [service, files]);
  };

  const getMetricsForService = (service) => {
    return service['metrics'].map((metric) => {
      return metric.name;
    });
  };

  return (
    <div className="container">
      <Button className="my-3" variant="success" onClick={handleResolveIncident} disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Resolve Incident'}
      </Button>
      <div className="issueData">
        <div className="row my-3">
          <strong>Summary: </strong>
          {issueData.summary ? (
            <span>{issueData.summary}</span>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>
        <div className="row my-3">
          <strong>Details: </strong>
          {issueData.details ? (
            <span>{issueData.details}</span>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>
        <div className="row my-3">
          <strong>Failure Origin: </strong>
          <span>{issueData.failure_origin}</span>
        </div>
        <div className="row my-3">
          <strong>Failure Reason: </strong>
          <span>{issueData.failure_reason}</span>
        </div>
        <div className="row my-3">
          <strong>System Definition:</strong>
        </div>
        {issueData.system?.services?.map((service) => (
          <div className="my-3" key={service.name}>
            <p className="my-0">
              <span>{service.name}: {service.description}</span>
            </p>
            <p className="my-0">
              <span>Depends on: {JSON.stringify(service.depends_on.concat(service.external_dependencies))}</span>
            </p>
            <p className="my-0">
              <span>Metrics: {JSON.stringify(getMetricsForService(service))}</span>
            </p>
          </div>
        ))}
        <div className="row my-3">
          <strong>Relevant Data: </strong>
        </div>
        {issueData.relevant_files && issueData.relevant_files.length > 0 ? (
          relevantDataByServices(issueData).map((relData) => (
            <div key={relData[0]}>
              <div className="row my-3"><strong>{relData[0]}</strong></div>
              {relData[1].map((fileName) => {
                if (fileName.endsWith(".json")) {
                  let metricData = JSON.parse(issueData.relevant_file_data[fileName]);
                  return (
                    <div key={fileName}>
                      <div className="row"><MetricView metricData={metricData} /></div>
                    </div>
                  );
                } else if (fileName.endsWith(".log")) {
                  let logData = issueData.relevant_file_data[fileName].split("\n");
                  return (
                    <div key={fileName}>
                      <div className="row"><LogsView logData={logData} stepTitle={fileName} /></div>
                    </div>
                  );
                } else {
                  return <div key={fileName}>Unknown file type: {fileName}</div>;
                }
              })}
            </div>
          ))
        ) : (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
      {failureMessage && <div className="row">{failureMessage}</div>}
    </div>
  );
};

function IssueViewer() {
  return <Container />;
}

export default IssueViewer;
