import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Dashboard from './routes/dashboard';
import Sandbox from './routes/sandbox'
import IssueViewer from './routes/issue_viewer';
import IssueAnalysisViewer from './routes/issue_analysis_viewer';
import DatasetViewer from './routes/dataset_viewer';
import CreateIncident from './routes/create_incident';
import IssueDetailsPage from './routes/issue_details';
import IssuesList from './routes/issue_list'

const router = createBrowserRouter([
  {
    path: "/",
    element: <IssuesList />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/sandbox/create",
    element: <CreateIncident />,
  },
  {
    path: "/admin/dataset_viewer",
    element: <DatasetViewer />,
  },
  {
    path: "/admin/issue_viewer",
    element: <IssueViewer />,
  },
  {
    path: "/admin/issue_analysis_viewer",
    element: <IssueAnalysisViewer />,
  },
  {
    path: "/issue_details",
    element: <IssueDetailsPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
