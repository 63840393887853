// import { useEffect, useMemo, useState, useRef, createRef } from 'react'

// const LogsView = (props) => {

//   const refs = useRef([]);
//   const [logLines, setLogLines] = useState([]);
//   refs.current = props.logData.map((_, i) => refs.current[i] || createRef());

//   useEffect(() => {
//     if (!props.logData) { return; }
//     let lines = props.logData.map((item) => {
//       return {
//         message: item,
//         expanded: false,
//         highlight: false,
//       }
//     });

//     if (props.highlight) {
//       var max_js = 0;
//       var max_match_indx = -1;
//       let words_in_highlight = new Set(props.highlight.split(/\s+/));

//       for (var i = 0; i < lines.length; i++) {
//         let words_in_line = lines[i].message.split(/\s+/);
//         let jaccard = new Set(words_in_line.filter(x => words_in_highlight.has(x))).size / new Set(words_in_line.concat(words_in_highlight)).size;
//         if (jaccard > max_js) {
//           max_js = jaccard;
//           max_match_indx = i;
//         }
//       }
//       if (max_match_indx >= 0) {
//         lines[max_match_indx]['highlight'] = true;
//       }
//     }
//     setLogLines(lines);
//   }, [props.logData, props.highlight]);

//   const divRef = useRef(null);

//   useEffect(() => {
//     for (var i = 0; i < logLines.length; i++) {
//       if (logLines[i].highlight) {
//         divRef.current.scrollTop = refs.current[i].current.offsetTop - refs.current[0].current.offsetTop - 100;
//         break;
//       }
//     }
//   }, [logLines]);


//   const getLineStyles = (item) => {
//     var mp = {
//       'overflow': 'hidden',
//       'cursor': 'pointer',
//     }
//     if (!item.expanded){
//       mp['height'] = '30px';
//     }
//     if (item.highlight) {
//       mp['background'] = 'yellow';
//     }
//     return mp
//   }

//   if (!logLines) { return ''; }

//   return  (
// 		<div>
//     	<h6 class="my-3">{props.stepTitle}</h6>
// 			{props.stepReason && <div>{props.stepReason}</div>}
//       {props.query && 
//       <div class="row my-3">
//         <p class="text-muted">Query (<small><a href={props.query.url} target='_blank'>View on {props.query.dataSource}</a></small>)</p>
//         <pre class="my-3">{props.query.rawQuery}</pre>
//       </div>}
//       {logLines.length > 0 && <div ref={divRef} style={{height: "400px", "overflow-y": "scroll"}}>
//         {logLines.map((item, indx) => {
//         return (
//           <div ref={refs.current[indx]} class="row py-1 text-nowrap border" style={getLineStyles(item)} onClick={() => {item.expanded = !item.expanded; setLogLines(logLines.slice());}}>
//             <div class="col-md-12">
//               <pre>{item['message']}</pre>
//             </div>
//           </div>
//         );})}
//       </div>}
//       {props.stepPostscript && <div class="row my-4">
//         <p><span>{props.stepPostscript}</span></p>
//       </div>}
//     </div>
//   );

// };

// export default LogsView;

import React, { useEffect, useState, useRef, createRef } from 'react';

const LogsView = (props) => {
  const refs = useRef([]);
  const [logLines, setLogLines] = useState([]);
  const divRef = useRef(null);

  refs.current = props.logData.map((_, i) => refs.current[i] || createRef());

  useEffect(() => {
    if (!props.logData) return;
    let lines = props.logData.map((item) => ({
      message: item,
      expanded: false,
      highlight: false,
    }));

    if (props.highlight) {
      const words_in_highlight = new Set(props.highlight.split(/\s+/));
      let max_js = 0;
      let max_match_indx = -1;

      lines.forEach((line, i) => {
        const words_in_line = line.message.split(/\s+/);
        const jaccard = new Set(words_in_line.filter(x => words_in_highlight.has(x))).size / new Set([...words_in_line, ...words_in_highlight]).size;
        if (jaccard > max_js) {
          max_js = jaccard;
          max_match_indx = i;
        }
      });

      if (max_match_indx >= 0) {
        lines[max_match_indx].highlight = true;
      }
    }
    setLogLines(lines);
  }, [props.logData, props.highlight]);

  useEffect(() => {
    const highlightedLineIndex = logLines.findIndex(line => line.highlight);
    if (highlightedLineIndex >= 0){
      divRef.current.scrollTop = refs.current[highlightedLineIndex].current.offsetTop - refs.current[0].current.offsetTop;
    }
  }, [logLines]);

  const getLineStyles = (item) => {
    const baseStyles = {
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      padding: '8px',
      borderBottom: '1px solid #e9ecef',
    };

    if (!item.expanded) {
      baseStyles.maxHeight = '30px';
      baseStyles.whiteSpace = 'nowrap';
      baseStyles.textOverflow = 'ellipsis';
    }

    if (item.highlight) {
      baseStyles.backgroundColor = '#fff3cd';
      baseStyles.borderLeft = '3px solid #ffc107';
    }

    return baseStyles;
  };

  if (!logLines.length) return null;

  return (
    <div className="my-4">
      {props.stepTitle && <h5 className="mb-3 text-primary">{props.stepTitle}</h5>}

      {props.stepReason && (
        <p className="mb-3 text-muted" style={{ fontSize: '0.95rem' }}>{props.stepReason}</p>
      )}

      {props.query && (
        <div className="mb-4">
          <p className="text-muted mb-2" style={{ fontSize: '0.9rem' }}>
            Query&nbsp;(gcp)
            {false && <span className="ms-2">
              (<a href={props.query.url} rel="noreferrer" target="_blank" className="text-info">View on {props.query.dataSource}</a>)
            </span>}
          </p>
          <pre className="bg-light p-3 rounded" style={{ fontSize: '0.85rem', color: '#333' }}>{props.query.rawQuery}</pre>
        </div>
      )}

      <div className="row mb-4">
        <h6 className="mb-3 text-secondary">Log Output</h6>
        <div ref={divRef} style={{ height: "400px", overflowY: "scroll", border: "1px solid #dee2e6", borderRadius: "4px" }}>
          {logLines.map((item, indx) => (
            <div class="row" 
              ref={refs.current[indx]} 
              style={getLineStyles(item)}
              onClick={() => {
                const newLogLines = [...logLines];
                newLogLines[indx].expanded = !newLogLines[indx].expanded;
                setLogLines(newLogLines);
              }}
            >
              <pre style={{ margin: 0, fontSize: '0.85rem', whiteSpace: item.expanded ? 'pre-wrap' : 'nowrap', wordBreak: 'break-all' }}>
                {item.message}
              </pre>
            </div>
          ))}
        </div>
      </div>

      {props.stepPostscript && (
        <div className="mt-4">
          <p className="fst-italic text-muted" style={{ fontSize: '0.95rem' }}>{props.stepPostscript}</p>
        </div>
      )}
    </div>
  );
};

export default LogsView;
