import { useEffect, useMemo, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap';

import MetricView from '../components/metrics'
import LogsView from '../components/logs'

import {
  Form,
  useLocation,
} from 'react-router-dom';


const LogAnalysisStepView = (props) => {
  let stepTitle = 'Step ' + props.stepIndx + ': Analyzing logs from service `' + props.analysisStepData['file_analyzed'] + '`';
  return (
    <LogsView logData={props.analysisStepData.observation.logLines} highlight={props.analysisStepData.observation.highlight} stepTitle={stepTitle} stepReason={props.analysisStepData.reason} query={props.analysisStepData.query} stepPostscript={props.analysisStepData.observation.relevance} />
  );
};

const MetricAnalysisStepView = (props) => {
  let stepTitle = 'Step ' + props.stepIndx + ': Analyzing metric `' + props.analysisStepData['metric_analyzed'] + '` from service `' + props.analysisStepData['service_analyzed'] + '`';
  let metricData = (props.analysisStepData.metric ?  [props.analysisStepData.metric] : []);
  return (
    <MetricView metricData={metricData} stepTitle={stepTitle} stepReason={props.analysisStepData.reason} stepPostscript={props.analysisStepData.summary} query={props.analysisStepData.query} />
  );
};

const AnalysisDataView = (props) => {
  return (<div>
  	<div class="row my-3">
    	<h4>Root Cause Analysis</h4>
     	{props.analysisData.analysisSteps.map((step, index) => {
      return (step['step_type'] === 'log_analysis' ? <LogAnalysisStepView stepIndx = {index + 1} analysisStepData={step} /> : <MetricAnalysisStepView stepIndx = {index + 1} analysisStepData={step} />);
      })}
      {props.analysisData.rootCause ? (
  null
) : (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    <span style={{ color: 'blue', marginLeft: '8px' }}>Relvy working its magic....</span>
  </div>
)}

    </div>
    <div class="row">
      <div class="col-sm-12">
        <h4 class="pt-3">Possible Root Cause</h4>
        {props.analysisData.rootCause ? (
            <p>{props.analysisData.rootCause}</p>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
      </div>
      <div class="row my-3">
        <div class="col-sm-12">
          
          {props.analysisData.nextStep && props.analysisData.nextStep !== "root_cause" &&
          (<div>
            <h4>Recommended Action</h4>
            <p>{props.analysisData.nextStep}</p>
            <p>{props.analysisData.nextStepReason}</p>
          </div>)
            }
        </div>
      </div>
    </div>
  </div>);
};

const Container = () => {
  function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
  }

  let queryParams = useQuery();

  const [gcpLocation, setGcpLocation] = useState(null);
  const [issueData, setIssueData] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const processIssueData = (data) => {
    setIssueData(data);
  }

  const build_log_query = (log_source_info) => {
    if (!log_source_info) {
      return null;
    }
    if (log_source_info.log_source_type === 'gcp'){
      let u = 'https://console.cloud.google.com/logs/query;query=' + encodeURIComponent(log_source_info.gcp.query);
      return {
        url: u.replace('(', '%2528').replace(')', '%2529'),
        dataSource: 'GCP',
        rawQuery: log_source_info.gcp.query
      };
    } else if (log_source_info.log_source_type === 'grafana'){
      let url = new URL(log_source_info.grafana.query);
      let rawQuery = url.searchParams.get('panes');
      return {
        url: log_source_info.grafana.query,
        dataSource: 'Grafana',
        rawQuery: rawQuery
      };
    }
    return null;
  };

  const build_metric_query = (analysisStepData) => {
    if ('gcp' in analysisStepData['data_source_info'] && 'query' in analysisStepData['data_source_info']['gcp']) {
      return {
        url: 'https://console.cloud.google.com/monitoring/metrics-explorer?pageState=' + encodeURIComponent(JSON.stringify(
          {"timeSeriesQuery": analysisStepData['data_source_info']['gcp']['query']})),
        rawQuery: analysisStepData['data_source_info']['gcp']['query'],
        dataSource: 'gcp'
      };
    }
    let query = {
      'metric': analysisStepData['metric_analyzed'],
    };
    if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['attributes']){
      query['attributes'] = analysisStepData['data_source_info']['metric']['attributes'];
    }
    if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['start_time']){
      query['start_time'] = analysisStepData['data_source_info']['metric']['start_time'];
    }
    if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['end_time']){
      query['end_time'] = analysisStepData['data_source_info']['metric']['end_time'];
    }
    return {
      url: null,
      rawQuery: JSON.stringify(query, null, 4),
      dataSource: null
    };
  };

  const createDisplayStepFromMetricsAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason) => {
    let ret = {
      'step_type': 'metrics_analysis',
      'step': displayNextStep,
      'reason': displayNextStepReason,
      'service_analyzed': analysisStepData["service_analyzed"],
      'metric_analyzed': analysisStepData["metric_analyzed"],
      'summary': analysisStepData["summary"],
      'query': build_metric_query(analysisStepData),
      'metric': analysisStepData['data_source_info']['metric']
    };
    return ret;
  };

  const createDisplayStepFromLogAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason) => {
    var rootCauseObservationIndx = -1;
    if (analysisStepData["observations"].length === 0) {
      return {
        'step_type': 'log_analysis',
        'step': displayNextStep,
        'reason': displayNextStepReason,
        'file_analyzed': analysisStepData["service_analyzed"],
        'query': build_log_query(analysisStepData["log_source_info"]),
        'observation': {
          'logLines': [],
          'relevance': "No logs found."
        }
      };
    }
    for (var observationIndx=0; observationIndx < analysisStepData["observations"].length; observationIndx++) {
      let observation = analysisStepData["observations"][observationIndx];
      if (observation["is_root_cause"]) {
        rootCauseObservationIndx = observationIndx;
        break;
      }
    }

    if (rootCauseObservationIndx === -1) {
      for (var observationIndx=0; observationIndx < analysisStepData["observations"].length; observationIndx++) {
        let observation = analysisStepData["observations"][observationIndx];
        if (observation["relevance_to_incident"] && observation["relevance_to_incident"].toLowerCase().includes("high")) {
          rootCauseObservationIndx = observationIndx;
          break;
        }
      }
    }

    // If no high relevance observation, show the first one
    if (rootCauseObservationIndx === -1) {
      rootCauseObservationIndx = 0;
    }

    let logObservation = analysisStepData["observations"][rootCauseObservationIndx]["log_message"];
    let logObservationRelevance = analysisStepData["observations"][rootCauseObservationIndx]["relevance_to_incident"];
    return {
        'step_type': 'log_analysis',
        'step': displayNextStep,
        'reason': displayNextStepReason,
        'file_analyzed': analysisStepData["service_analyzed"],
        'query': build_log_query(analysisStepData["log_source_info"]),
        'observation': {
          'logLines': analysisStepData["log_source_info"]["logs"],
          'highlight': logObservation,
          'relevance': logObservationRelevance
        }
    };
  };

  const processAnalysisData = (data) => {
    var output = {};      
    
    let finalResponse = data["final_response"];
    if ("next_step" in finalResponse) {
      output['nextStep'] = finalResponse["next_step"] === 'request_logs' ? "Request logs from " + finalResponse["params"] : (
        finalResponse["next_step"] === 'request_metrics' ? "Request metrics from " + finalResponse["params"]["service"] + ' - ' + finalResponse["params"]["metric_name"] : finalResponse["next_step"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
    }

    var displayAnalysisSteps = [];
    var displayNextStep = "";
    var displayNextStepReason = "";
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let [analysisStepType, analysisStepData] = data["analysis_history"][stepIndx];
      if (analysisStepType === "next_step") {
        if (analysisStepData["next_step"] === "request_logs") {
          displayNextStep = "Process logs from " + analysisStepData["params"]["service"];
        } else if (analysisStepData["next_step"] === "request_metrics") {
          displayNextStep = "Process metrics from " + analysisStepData["params"]["service"] + ' - ' + analysisStepData["params"]["metric_name"];
        } else {
          displayNextStep = analysisStepData["next_step"];
        }
        displayNextStepReason = analysisStepData["reason"];
      } else if (analysisStepType === "observation"){
        if (analysisStepData["observation_type"] === 'log_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromLogAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else if (analysisStepData["observation_type"] === 'metrics_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromMetricsAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else {
          console.log("Unknown observation type: " + analysisStepData["observation_type"]);
        }
      }
    }
    output['analysisSteps'] = displayAnalysisSteps;
    console.log(output);
    setAnalysisData(output);
  };

  useEffect(() => {
    if (queryParams.get('gcp_location')) {
      setGcpLocation(queryParams.get('gcp_location'));
    }
  }, []);

  const fetchIssueData = async () => {
    if (!gcpLocation) { return; }
    let gcpLocationEncoded = encodeURIComponent(gcpLocation);
    const url = "http://localhost:5000/api/admin/issue_viewer?gcp_location=" + gcpLocationEncoded;;

    // API call options
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options)
    if (response.ok){
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  const fetchAnalysisData = async () => {
    if (!gcpLocation) { return; }
    let gcpLocationEncoded = encodeURIComponent(gcpLocation);
    const url = "http://localhost:5000/api/admin/issue_analysis_viewer?gcp_location=" + gcpLocationEncoded;;

    // API call options
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options)
    if (response.ok){
      processAnalysisData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  // useEffect(() => {
  //   fetchIssueData();
  //   fetchAnalysisData();
  // }, [gcpLocation]);

  useEffect(() => {
    fetchIssueData();
    fetchAnalysisData();
    const id = setInterval(() => {
      fetchAnalysisData();
    }, 5000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [gcpLocation]);

  useEffect(() => {
    if (analysisData && analysisData.rootCause) {
      clearInterval(intervalId);
    }
  }, [analysisData, intervalId]);


  const relevantDataByServices = (data) => {
    if (!data) { return; }

    let relevantData = new Map();
    data['system']['services'].forEach((service) => {
      data['relevant_files'].forEach((fileName) => {
        if (fileName.startsWith(service.name)) {
          if (!relevantData.has(service.name)) {
            relevantData.set(service.name, []);
          }
          relevantData.get(service.name).push(fileName);
        }
      });
    });
    let retVal = Array.from(relevantData, ([service, files]) => [service, files]);
    return retVal;
  }

  const getMetricsForService = (service) => {
    return service['metrics'].map((metric) => {
      return metric.name;
    });
  }


  return (
    <div class="container">
       {issueData && <div class="issueData">
         <div class="row my-3"><strong>Summary: </strong><span>{issueData["summary"]}</span></div>
         <div class="row my-3"><strong>Details: </strong><span>{issueData["details"]}</span></div>
         <details>
           <summary>See more issue details</summary>

           <div class="row my-3"><strong>Failure Origin: </strong><span>{issueData["failure_origin"]}</span></div>
           <div class="row my-3"><strong>Failure Reason: </strong><span>{issueData["failure_reason"]}</span></div>
           <div class="row my-3"><strong>System Definition:</strong></div>
           {issueData["system"]["services"].map((service) => {
           return (<div class="my-3">
             <p class="my-0"><span>{service.name}: {service.description}</span></p>
             <p class="my-0"><span>Depends on: {JSON.stringify(service['depends_on'].concat(service['external_dependencies']))})</span></p>
             <p class="my-0"><span>Metrics: {JSON.stringify(getMetricsForService(service))}</span></p>
           </div>);})}
           <div class="row my-3"><strong>Relevant Data: </strong></div>
           {relevantDataByServices(issueData).map((relData) => {
           return (<div>
             <div class="row my-3"><strong>{relData[0]}</strong></div>
            {relData[1].map((fileName) => {
              if (fileName.endsWith(".json")){
                let metricData = JSON.parse(issueData["relevant_file_data"][fileName]);
                return (<div>
                  <div class="row"><MetricView metricData={metricData} /></div>
                </div>);
              } else if (fileName.endsWith(".log")){
                let logData = issueData["relevant_file_data"][fileName].split("\n");
                return (<div>
                  <div class="row"><p class="my-3"><strong>{fileName}</strong></p></div>
                  <div class="row"><LogsView logData={logData} /></div>
                </div>);
              } else {
                console.log("Unknown file type: " + fileName);
                return "";
              }
            })}
          </div>);})} 
         </details>
       </div>}
       {analysisData && <AnalysisDataView analysisData={analysisData} />}
       {failureMessage && <div class="row">
         {failureMessage}
       </div>}
      </div>
  );
}

function IssueAnalysisViewer() {
  return (
    <Container />
  );
}

export default IssueAnalysisViewer;
