import { useEffect, useState } from 'react'

import {
  Form,
} from 'react-router-dom';


const Container = () => {
  const [version, setVersion] = useState(null);
  const [issuesList, setIssuesList] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);

  const processIssueData = (data) => {
    setIssuesList(data);
  }

  const fetchDatasetIssues = async () => {
    if (!version) { return; }
    const url = "http://localhost:5000/api/admin/datasets/" + version;

    // API call options
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options)
    if (response.ok){
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchDatasetIssues();
  }, [version]);

  const getIssueUrl = (issueId) => {
    return "/admin/issue_viewer?gcp_location=" + encodeURIComponent(issueId);
  };

  return (
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <Form>
            <input type="text" class="form-control" placeholder="Dataset Version"  onKeyPress={(e) => { if (e.key === 'Enter') { setVersion(e.target.value) } }} />
          </Form>
        </div>
      </div>
       {issuesList && <div class="issueData">
         {issuesList.map((issue) => {
         return (<div class="row my-3"><a target='_blank' rel="noreferrer" href={getIssueUrl(issue)}>{issue}</a></div>);
         })}
         </div>}
         {failureMessage && <div class="row my-3">{failureMessage}</div>}
    </div>
  );
}

function DatasetViewer() {
  return (
    <Container />
  );
}

export default DatasetViewer;
