import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Card, Modal } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdArrowBackIosNew } from 'react-icons/md';
import './Sandbox.css';
import logoImg from '../../src/img/logo.jpg'
// import TutorialModal from './components/TutorialModal';

const BASE_URL = 'http://127.0.0.1:5000/api/sandbox'; // Adjust this to your API base URL

const TutorialModal = ({ show, onHide, title, imageSrc, content, onNext, onBack }) => {
    return (
      <Modal show={show} centered onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imageSrc &&
            <div className='tutorial-image-container'>
                <img src={imageSrc} alt={title} style={{ width: '50%' }} />
            </div>}
          <p>{content}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
            {onBack && (
                <MdArrowBackIosNew  style={{ cursor: 'pointer' }} onClick={onBack} />
            )}
            <Button variant="success" onClick={onNext} className="ml-auto">
            Next
            </Button>
            
        </Modal.Footer>
      </Modal>
    );
  };

function Sandbox() {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      title: "Welcome to Relvy AI Demo",
      imageSrc: logoImg,
      content: "Relvy ingests your logs, metrics, documentation and replicates all steps Engineers take to troubleshoot issues. Teams spend 15x less time on incident response",
    },
    {
      title: "Tutorial Step 2",
      imageSrc: null,
      content: "This is the second step of the tutorial. You're doing great!",
    },
    // Add more steps as needed
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(-1); // Close the modal when all steps are done
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    setCurrentStep(-1);
  };

  return (
    <Container className="App1">
      <Header />
      <Home />
      {currentStep >= 0 && (
        <TutorialModal
          show={currentStep >= 0}
          onHide={handleClose}
          title={steps[currentStep].title}
          imageSrc={steps[currentStep].imageSrc}
          content={steps[currentStep].content}
          onNext={handleNext}
          onBack={handleBack}
        />
      )}
    </Container>
  );
}

function Header() {
  return (
    <header className="App-header1">
      <h1>Relvy AI</h1>
      <h3>The future of incident management</h3>
    </header>
  );
}

function Home() {
  return (
    <div className="home">
      <strong>Select one of the following incidents to see the magic of Relvy</strong>
      <IncidentCards />
      <Link to="/sandbox/create">
        <Button variant="success">Create Incident</Button>
      </Link>
    </div>
  );
}

function IncidentCard({ incident }) {
  const cardHeight = '16rem';
  const cardWidth = '36rem';

  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/issue_viewer?gcp_location=datasets/v3/${incident.system.name}/${incident.locator}/issue.json`);
  };

  return (
    <Card key={incident.uuid} style={{ width: cardWidth }} className="incident-card m-2" onClick={handleCardClick}>
      <Card.Body>
        <div className="card-header">
          <span className="card-title">{incident.summary.length > 30 ? incident.summary.substring(0, 30) + '...' : incident.summary}</span>
          <span className="card-date">{new Date(incident.issue_start_time).toLocaleDateString()}</span>
        </div>
        <div className="card-body">
          <p className="card-description">{incident.details.length > 200 ? incident.details.substring(0, 100) + '...' : incident.details}</p>
        </div>
        <div className="card-footer">
          <span className="card-architecture"><strong>{incident.system.name}</strong></span>
        </div>
      </Card.Body>
    </Card>
  );
}

function IncidentCards() {
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIncidents = async () => {
      try {
        const systemId = encodeURIComponent("Content Moderation System");
        const totalCount = 10; // Adjust as needed
        const url = `${BASE_URL}/incidents_list?system_name=${systemId}&total_count=${totalCount}`;

        const response = await axios.get(url);
        console.log('Response data:', response.data);
        setIncidents(response.data.incidents);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchIncidents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="cards">
      {incidents.map((incident) => (
        <IncidentCard key={incident.uuid} incident={incident} />
      ))}
    </div>
  );
}

export default Sandbox;
