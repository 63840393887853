import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sandbox.css';

const BASE_URL = 'http://127.0.0.1:5000/api/sandbox'; // Adjust this to your API base URL

function CreateIncident() {
  const navigate = useNavigate();
  const [description, setDescription] = useState('');
  const [architecture, setArchitecture] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Create a new incident
      const response = await axios.post(`${BASE_URL}/incidents/`, {
        system_name: architecture,
        incident_description: description,
      });

      const { id } = response.data;

      // Poll the generation task status
      const pollGenerationStatus = async (taskId) => {
        try {
          const statusResponse = await axios.get(`${BASE_URL}/generation_tasks/${taskId}`);
          const { generation_status, locator } = statusResponse.data;

          if (generation_status !== "NOT_STARTED" && locator!=null) {
            // Redirect to issue viewer once the generation status is updated
            const systemId = encodeURIComponent(architecture);            
            const gcp_location = `datasets/v3/${systemId}/${locator}/issue.json`;

            navigate(`/issue_viewer?gcp_location=${gcp_location}`);
          } else {
            // Poll again after 2 seconds
            setTimeout(() => pollGenerationStatus(taskId), 2000);
          }
        } catch (error) {
          console.error("Error polling generation status:", error);
        }
      };

      // Start polling
      pollGenerationStatus(id);

    } catch (error) {
      console.error("Error creating incident:", error);
      setLoading(false);
    }
  };

  return (
    <Container className="create-incident">
      <h2>Create a New Incident</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label className="bold-label">Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Describe the incident in detail..."
            required
            className="custom-textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="bold-label">Architecture</Form.Label>
          <div className="col-md-3">
            <Form.Control
              as="select"
              required
              className="custom-select"
              value={architecture}
              onChange={(e) => setArchitecture(e.target.value)}
            >
              <option value="" disabled>Select an architecture</option>
              <option value="Content Moderation System">Content Moderation System</option>
              <option value="E-commerce Product Recommendation Engine">E-commerce Product Recommendation Engine</option>
              <option value="Event Management System">Event Management System</option>
              <option value="Event Ticketing Backend Service">Event Ticketing Backend Service</option>
              <option value="Event-Based Notification System">Event-Based Notification System</option>
            </Form.Control>
          </div>
        </Form.Group>
        <Button type="submit" variant="success" disabled={loading}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Create'}
        </Button>
      </Form>
    </Container>
  );
}

export default CreateIncident;
