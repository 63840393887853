import React, { useState } from 'react';

import { Card, Placeholder, Modal } from 'react-bootstrap';

import MetricView from '../components/metrics';
import LogsView from '../components/logs';

import { Nav, Button, Form, ListGroup, Collapse} from 'react-bootstrap';

import { FileEarmarkText, FileEarmarkBarGraph } from 'react-bootstrap-icons';
import { ChevronLeft, ChevronRight, ArrowLeft } from 'react-bootstrap-icons';
import {  PersonCircle, Lightning, Clock } from 'react-bootstrap-icons';

import ReactFlow, { 
  Controls, 
  Background, 
  useNodesState, 
  useEdgesState,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';


export const CustomPlaceholder = ({ xs = 12, size = "lg", ...props }) => (
<Placeholder animation="glow" {...props}>
    <Placeholder xs={xs} size={size} />
</Placeholder>
);

export const ParagraphPlaceholder = () => (
<Placeholder as="p" animation="glow">
    <Placeholder xs={12} />
    <Placeholder xs={10} />
    <Placeholder xs={8} />
</Placeholder>
);

export const Breadcrumb = ({ issue }) => (
    issue?.system?.name ? (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/?from=details">Back to all issues</a>
          </li>
        </ol>
      </nav>
    ) : (
      <CustomPlaceholder xs={4} size="xs" />
    )
  );

export const AttachmentModal = ({ show, onHide, file, fileData }) => {
    const isLogFile = file && file.endsWith('.log');

    const getTitle = (file) => {
      if (!file) {
        return '';
      }

      if (file.endsWith('.json')) {
        return file.replace('.json', '').split('_').slice(1).join('_');
      }
      return file;
    }
  
    return (
      <Modal
        show={show}
        onHide={onHide}
        size={isLogFile ? "xl" : "lg"}
        dialogClassName={isLogFile ? "modal-90w" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getTitle(file)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {file && file.endsWith('.json') ? (
            <MetricView metricData={JSON.parse(fileData)} />
          ) : isLogFile ? (
            <LogsView logData={fileData.split('\n')} />
          ) : (
            <div>Unknown file type: {file}</div>
          )}
        </Modal.Body>
      </Modal>
    );
};

export const Attachment = ({ fileName, onClick }) => {
    const isMetric = fileName.endsWith('.json');
    const Icon = isMetric ? FileEarmarkBarGraph : FileEarmarkText;

    const getTitle = (file) => {
      if (!file) {
        return '';
      }
  
      if (file.endsWith('.json')) {
        return file.replace('.json', '').split('_').slice(1).join('_');
      }
      return file;
    };
  
    return (
      <Card 
        className="attachment-card" 
        onClick={onClick}
        style={{ 
          cursor: 'pointer', 
          transition: 'all 0.3s ease',
          width: '200px',
          height: '80px'
        }}
      >
        <Card.Body className="d-flex flex-column justify-content-between p-2">
          <div className="d-flex align-items-center">
            <Icon className="me-2" size={24} />
            <span className="text-truncate" style={{ 
              fontSize: '0.85rem',
              fontWeight: 'bold',
              flex: 1
            }}>
              {getTitle(fileName)}
            </span>
          </div>
          <small className="text-muted">{isMetric ? 'Metric' : 'Log'}</small>
        </Card.Body>
      </Card>
    );
  };

export const CommentsCard = () => (
    <Card>
      <Card.Body>
        <Card.Title>Comments</Card.Title>
        <Form>
          <div className="d-flex align-items-center">
            <PersonCircle size={32} className="me-2" />
            <Form.Group className="flex-grow-1 me-2">
              <Form.Control as="input" placeholder="Add a comment..." />
            </Form.Group>
            <Button variant="secondary">
              Add
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );


// ######### Side bars ##################

export const Sidebar = ({ isOpen, toggleSidebar }) => (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <Button 
        variant="light" 
        className="toggle-btn" 
        onClick={toggleSidebar}
      >
        {isOpen ? <ChevronLeft /> : <ChevronRight />}
      </Button>
      {isOpen && (
        <div className="sidebar-content">
          <Nav.Link href="#" className="back-to-project">
            <ArrowLeft /> Back
          </Nav.Link>
          <h6 className="sidebar-heading">DEFAULT FILTERS</h6>
          <Nav className="flex-column">
            <Nav.Link href="#">All issues</Nav.Link>
            <Nav.Link href="#" className="active">My open issues</Nav.Link>
            <Nav.Link href="#">Reported by me</Nav.Link>
            <Nav.Link href="#">Open issues</Nav.Link>
            <Nav.Link href="#">Done issues</Nav.Link>
            <Nav.Link href="#">Viewed recently</Nav.Link>
            <Nav.Link href="#">Resolved recently</Nav.Link>
            <Nav.Link href="#">Updated recently</Nav.Link>
          </Nav>
          <Nav.Link href="#" className="view-all-filters">View all filters</Nav.Link>
          <div className="company-info">
            <p>You're in a company-managed project</p>
            <a href="#">Learn more</a>
          </div>
        </div>
      )}
    </div>
  );

  const MoreFieldsCard = () => {
    const [showFields, setShowFields] = useState(true);
    const [fields, setFields] = useState([
      { name: 'Original estimate', value: '8h' },
      { name: 'Story points', value: '5' },
      { name: 'Sprint', value: 'Sprint 23' },
      { name: 'Component', value: 'Backend' },
      { name: 'Epic link', value: 'PROJ-123' },
    //   { name: 'Priority', value: 'High' },
    //   { name: 'Assignee', value: 'John Doe' },
    ]);
  
    const addRandomField = () => {
      const randomFields = [
        { name: 'Story points', value: '5' },
        { name: 'Sprint', value: 'Sprint 23' },
        { name: 'Component', value: 'Backend' },
        { name: 'Epic link', value: 'PROJ-123' },
        { name: 'Labels', value: 'bug, critical' },
      ];
      const newField = randomFields[Math.floor(Math.random() * randomFields.length)];
      setFields([...fields, newField]);
    };
  
    return (
      <Card className="mb-3">
        <Card.Header as="h6" className="d-flex justify-content-between align-items-center">
          More fields
          {/* <Button variant="outline-secondary" size="sm" onClick={addRandomField}>
            <FaPlus />
          </Button> */}
        </Card.Header>
        <Card.Body>
          <Button
            variant="light"
            className="w-100 text-start mb-2"
            onClick={() => setShowFields(!showFields)}
            aria-controls="more-fields-collapse"
            aria-expanded={showFields}
          >
            {showFields ? 'Hide fields ▲' : 'Show fields ▼'}
          </Button>
          <Collapse in={showFields}>
            <div id="more-fields-collapse">
              {fields.map((field, index) => (
                <div key={index} className="d-flex justify-content-between mb-2">
                  <span>{field.name}</span>
                  <span className="text-muted">{field.value}</span>
                </div>
              ))}
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    );
  };

export const RightSidebar = ({ issue }) => (
    <div className="right-sidebar">
      <div className="d-flex mb-3">
        <Button variant="outline-secondary" size="sm" className="me-2">Attach</Button>
        <Button variant="outline-secondary" size="sm" className="me-2">Create subtask</Button>
        {/* <Button variant="outline-secondary" size="sm" className="me-2">Link issue</Button> */}
        {/* <Button variant="light" className="w-50 me-2">To Do ▼</Button> */}
        {/* <Button variant="light" className="w-50"><Lightning /> Actions ▼</Button> */}
      </div>
  
      <Card className="mb-3">
        <Card.Header as="h6">Details</Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="fw-bold">Assignee</div>
            {issue?.assignee && issue.assignee !== "" ? (
              <div className="d-flex align-items-center">
                <PersonCircle className="me-2" />
                {issue.assignee}
              </div>
            ) : (
              <CustomPlaceholder xs={6} size="sm"/>
            )}
            <a href="#" className="text-primary">Assign to me</a>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="fw-bold">Reporter</div>
            {issue?.reporter && issue.reporter !== "" ? (
              <div className="d-flex align-items-center">
                <div className="reporter-avatar me-2">{issue.reporter.charAt(0)}</div>
                {issue.reporter}
              </div>
            ) : (
              <CustomPlaceholder xs={6} size="sm"/>
            )}
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="fw-bold">Labels</div>
            {issue?.labels && issue.labels !== "" ? issue.labels : <CustomPlaceholder xs={4} size="sm"/>}
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="fw-bold">Priority</div>
              {issue?.priority ? (
                <div className="d-flex align-items-center">
                  <div className="priority-indicator me-2"></div>
                  {issue.priority}
                </div>
              ) : (
                <CustomPlaceholder xs={4} size="sm"/>
              )}
          </ListGroup.Item>
        </ListGroup>
      </Card>
  
      <MoreFieldsCard />
  
  
      <div className="text-muted small">
        <div><Clock className="me-1" /> Created {issue.createdDate}</div>
        <div><Clock className="me-1" /> Updated {issue.updatedDate}</div>
      </div>
    </div>
);


// System Diagrams

export const SystemDiagram = ({ services }) => {


    services.forEach(service => {
      service.all_deps = (service.depends_on || []).concat(service.external_dependencies || []);
    });

  // sort services by number of dependencies

    const perRow = 2;

    const maxY = 100 + 100 * Math.floor(services.length / perRow);

    const allExternalDependencies = new Set();
    services.forEach(service => {
      service.external_dependencies.forEach(depName => {
        allExternalDependencies.add(depName);
      });
    });

    const assignPositions = (services) => {
      const positions = new Map();
      const numInNodes = new Map();
      services.forEach((service, index) => {
        service.all_deps.forEach(depName => {
          numInNodes.set(depName, (numInNodes.get(depName) || 0) + 1);
        });
      });

      services.sort((a, b) => (numInNodes.get(a.name) || 0) - (numInNodes.get(b.name) || 0));

      services.forEach((service, index) => {
        positions.set(service.name, {
          x: 250 * Math.floor(index % perRow) + Math.random() * 50,
          y: 100 + 100 * Math.floor(index / perRow) + Math.random() * 20,
        });
      });

      // set for external dependencies
      const extPerRow = 4;
      Array.from(allExternalDependencies).forEach((depName, index) => {
          if (!positions.has(depName)) {
            positions.set(depName, {
              x: 250 * Math.floor(index % extPerRow) + Math.random() * 50,
              y: maxY + 100 + 100 * Math.floor(index / extPerRow) + Math.random() * 20,
            });
          }
      });

      return positions;
    };

    const nodePositions = assignPositions(services);

    const getPosition = (service, index) => {
      return nodePositions.get(service.name);
    };


    const initialNodes = services.map((service, index) => ({
      id: service.name,
      data: { label: service.name },
      position: getPosition(service, index),
      style: {
        background: '#f0f0f0',
        border: '1px solid #999',
        borderRadius: '5px',
        padding: 10,
        width: 180,
        textAlign: 'center',
      },
    })).concat(Array.from(allExternalDependencies).map((depName, index) => ({
      id: depName,
      data: { label: depName },
      position: getPosition({ name: depName, all_deps: [] }, index),
      style: {
        background: '#f0f0f0',
        border: '1px solid #999',
        borderRadius: '5px',
        padding: 10,
        width: 180,
        textAlign: 'center',
      },
    })));

    const initialEdges = services.flatMap(service => 
      service.all_deps.map(depName => ({
        id: `${service.name}-${depName}`,
        source: service.name,
        target: depName,
        type: 'bezier',
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        style: {
          // pick a random color
          stroke: `#${Math.floor(Math.random()*16777215).toString(16)}`,
        }
      }))
    );
  
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView={true}
        >
          <Controls showInteractive={false}/>
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
    );
};

export const SystemDiagramModal = ({ show, onHide, system }) => {
    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{system.name} Diagram</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>{system.description}</p>
        <h6 style={{ textAlign: 'center' }}>Microservice Architecture</h6>
        <div style={{ height: '400px' }}>
            <SystemDiagram services={system.services} />
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
