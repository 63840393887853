// src/components/IssuesList.js
import React from 'react';
import { useEffect, useState } from 'react'
import { Modal, Table, Badge, Button, Spinner } from 'react-bootstrap';
import { MdArrowBackIosNew } from 'react-icons/md';
import { IoInformationCircle } from 'react-icons/io5';

import logoImg from '../../src/img/logo.jpg'
import { useLocation } from 'react-router-dom'; 

import { SystemDiagramModal } from './issue_details_components';

import './Sandbox.css';

const assignees = [
  'Alice',
  'Bob',
  'John',
];

const priorities = [
  'High',
  'Medium',
  'Low',
];

const statuses = [
  'To Do',
  'In Progress',
];

const statusColors = {
  'To Do': 'secondary',
  'In Progress': 'primary',
  Done: 'success',
  Closed: 'dark',
};

const priorityColors = {
  High: 'danger',
  Medium: 'warning',
  Low: 'info',
};


const TutorialModal = ({ show, onHide, title, imageSrc, content, stepIndx, onNext, onBack }) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton style={{ borderBottom: 'none', paddingBottom: '0' }}>
        <Modal.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        {imageSrc &&
          <div className='tutorial-image-container'>
              <img src={imageSrc} alt={title} style={{ width: '50%' }} />
          </div>}
          <hr style={{ margin: '20px 0', borderTop: '1px solid #e0e0e0' }} />
          <p style={{ padding: '15px 0', fontSize: '16px', lineHeight: '1.5' }}>{content}</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
          {onBack && (
              <MdArrowBackIosNew  style={{ cursor: 'pointer' }} onClick={onBack} />
          )}
          <Button 
            variant="success"
            // style={{ backgroundColor: '#5935c9', borderColor: '#5935c9' }} 
            onClick={onNext} 
            className="ml-auto tutorial-next-button"
          >
            {stepIndx === 1 ? 'Go!' : 'Next'}
          </Button>
      </Modal.Footer>
    </Modal>
  );
};


const IssuesTable = ({ system_name }) => {

  const [issues, setIssues] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);

  const [showDiagramModal, setShowDiagramModal] = useState(false);
  const [systemData, setSystemData] = useState(null);
  const [loadingSystemData, setLoadingSystemData] = useState(false);

  const processIssueData = (data) => {
    console.log(data);
    setIssues(data['incidents']);
    setSystemData(data['incidents'][0]['system']);
  }

  const fetchIssues = async () => {
    
    const url = `/api/sandbox/incidents_list?system_name=${system_name}`;

    // API call options
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options)
    if (response.ok){
      processIssueData(await response.json());
    } else {
      setFailureMessage('failed to fetch data');
    }
  };

  useEffect(() => {
    fetchIssues();
  }, [system_name]);

  if (!system_name) {
    return '';
  }

  return (
    <div>
      <h4 onClick={() => setShowDiagramModal(true)} style={{cursor: 'pointer'}}>
        {system_name}
        <IoInformationCircle style={{marginLeft: '10px', color: '#5935c9'}}/>
      </h4>
      <Table bordered hover responsive className="issues-table my-3">
        <thead>
          <tr>
            <th>Type</th>
            <th>Summary</th>
            <th>Assignee</th>
            <th>Project</th>
            <th>Status</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          {!issues && <tr><td colSpan="6"><span class="text-center"><Spinner animation="border" role="status" variant="purple"></Spinner></span></td></tr>}
          {issues && issues.map(issue => {
            let status = statuses[Math.floor(Math.random() * statuses.length)];
            let priority = priorities[Math.floor(Math.random() * priorities.length)];
            let assignee = assignees[Math.floor(Math.random() * assignees.length)];
            let project = system_name;
            return (
            <tr key={issue.id} style={{cursor: 'pointer'}} onClick={() => { window.location.href = "/issue_details?system_name=" + encodeURIComponent(system_name) + "&locator=" + encodeURIComponent(issue.locator) }}>
              <td>BUG</td>
              <td>{issue.summary}</td>
              <td>{assignee}</td>
              <td>{project}</td>
              <td>
                <Badge bg={statusColors[status]}>{status}</Badge>
              </td>
              <td>
                <Badge bg={priorityColors[priority]}>{priority}</Badge>
              </td>
            </tr>);
            })}
        </tbody>
      </Table>
      {systemData && (
        <SystemDiagramModal
          show={showDiagramModal}
          onHide={() => setShowDiagramModal(false)}
          system={systemData}
        />
      )}
      {/* <div class="row">
        <div class="offset-sm-10 col-sm-2">
          <Button variant="success" className='float-end'>Create Incident</Button>
        </div>
      </div> */}
    </div>
  );
};

const Container = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [showTutorial, setShowTutorial] = useState(false);
  const location = useLocation();

  const steps = [
    {
      title: "Welcome to Relvy AI Demo",
      imageSrc: logoImg,
      content: "Relvy ingests your logs, metrics and documentation and replicates the steps engineers take to troubleshoot issues. Teams spend 15x less time on incident response",
    },
    {
      title: "Welcome to Relvy AI Demo",
      imageSrc: logoImg,
      content: "In this sandbox, we have deployed various microservice architectures and simulated real-life production incidents. Select any incident to see how Relvy can help you troubleshoot faster.",
    },
    // Add more steps as needed
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isFromDetails = searchParams.get('from') === 'details';

    if (!isFromDetails) {
      setShowTutorial(true);
      setCurrentStep(0);
    }
  }, [location]);

  useEffect(() => {
    const cookie = document.cookie;
    if (cookie.includes('tutorial=done')) {
      setShowTutorial(false);
    }
  }, []);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(-1);
      setShowTutorial(false);
      document.cookie = "tutorial=done";
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    setCurrentStep(-1);
    setShowTutorial(false);
    document.cookie = "tutorial=done";
  };

  const systemNames = [
    'E-commerce Recommendation Engine',
    'Personal Financial Management (PFM) System',
    'Personalized Health and Wellness Tracker',
    'Content Delivery System for E-Learning Platform',
  ];

  return (
    <div className="container">
      {showTutorial && (
        <TutorialModal
          show={showTutorial}
          onHide={handleClose}
          title={steps[currentStep].title}
          imageSrc={steps[currentStep].imageSrc}
          content={steps[currentStep].content}
          stepIndx={currentStep}
          onNext={handleNext}
          onBack={handleBack}
        />
      )}
    <div className="issues-list">
        {systemNames.map((system_name) => {
          return (<IssuesTable key={system_name} system_name={system_name} />);
        })}
      </div>
    </div>
  );

};

function IssuesList() {
    return <Container />;
}

  
export default IssuesList;
