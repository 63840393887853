import './Sandbox.css';

import { useEffect, useMemo, useState } from 'react';
import { Accordion, Container, Row, Col, Card, Spinner, Tabs, Tab } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';

import {CustomPlaceholder, ParagraphPlaceholder} from './issue_details_components';
import {Attachment, AttachmentModal, Breadcrumb, CommentsCard} from './issue_details_components';
import {Sidebar, RightSidebar} from './issue_details_components';
import {createDisplayStepFromLogAnalysisData, createDisplayStepFromMetricsAnalysisData} from './utils';

import MetricView from '../components/metrics';
import LogsView from '../components/logs';

import Joyride, { STATUS, EVENTS } from 'react-joyride';

const DetailsTab = ({ issue, onShowModal }) => (
  <>
    <DescriptionCard details={issue.details} />
    <AttachmentsCard 
      relevantFiles={issue.relevant_files}
      services={issue.system?.services}
      relevantFileData={issue.relevant_file_data}
      onShowModal={onShowModal}
    />
    <CommentsCard />
  </>
);

const AnalysisTab = ({ analysisData }) => (
  analysisData ? (
    <div>
      <AnalysisDataView analysisData={analysisData} />
    </div>
  ) : (
    <div className="text-center py-4">
      <Spinner animation="border" role="status" variant="purple">
        <span className="visually-hidden">Loading analysis...</span>
      </Spinner>
    </div>
  )
);

const DescriptionCard = ({ details }) => (
  <Card className="mb-3 description-card">
    <Card.Body>
      <Card.Title>Description</Card.Title>
      <Card.Text>{details || <ParagraphPlaceholder />}</Card.Text>
    </Card.Body>
  </Card>
);

const AttachmentsCard = ({ relevantFiles, services, onShowModal }) => {
  const relevantDataByServices = () => {
    if (!relevantFiles || !services) {
      return [];
    }

    let relevantData = new Map();
    services.forEach((service) => {
      relevantFiles.forEach((fileName) => {
        if (fileName.startsWith(service.name)) {
          if (!relevantData.has(service.name)) {
            relevantData.set(service.name, []);
          }
          relevantData.get(service.name).push(fileName);
        }
      });
    });
    return Array.from(relevantData, ([service, files]) => [service, files]);
  };

  return (
    <Card className="mb-3 attachments-card">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between align-items-center mb-3">
          See logs and metrics
        </Card.Title>
        {!relevantFiles ? (
          <div className="text-center py-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          relevantDataByServices().map(([service, files]) => (
            <div key={service} className="mb-3">
              <h6>Service Name: {service}</h6>
              <div className="d-flex flex-wrap gap-3">
                {files.map((fileName) => (
                  <Attachment
                    key={fileName}
                    fileName={fileName}
                    onClick={() => onShowModal(fileName)}
                  />
                ))}
              </div>
            </div>
          ))
        )}
      </Card.Body>
    </Card>
  );
};


const LogAnalysisStepView = (props) => {
  let stepTitle = `Analyzing logs from service '${props.analysisStepData['file_analyzed']}'`;
  let stepTitleComponents = {
    'action': 'logs',
    'service': props.analysisStepData['file_analyzed'],
    'stepIndx': props.stepIndx,
  };
  return (
		<LogsView 
			logData={props.analysisStepData.observation.logLines} 
			highlight={props.analysisStepData.observation.highlight} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			query={props.analysisStepData.query} 
			stepPostscript={props.analysisStepData.observation.relevance} 
		/>
  );
};

const MetricAnalysisStepView = (props) => {
  let stepTitle = `Analyzing metric '${props.analysisStepData['metric_analyzed']}' from service '${props.analysisStepData['service_analyzed']}'`;
  let metricData = props.analysisStepData.metric ? [props.analysisStepData.metric] : [];
  let stepTitleComponents = {
    'action': 'metrics',
    'metric': props.analysisStepData['metric_analyzed'],
    'service': props.analysisStepData['service_analyzed'],
    'stepIndx': props.stepIndx,
  };

  return (
		<MetricView 
			metricData={metricData} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			stepPostscript={props.analysisStepData.summary} 
			query={props.analysisStepData.query} 
		/>
  );
};

const AnalysisSteps = (props) => {
  const getStepTitle = (step, index) => {
    if (step['step_type'] === 'log_analysis') {
      return `Step ${index + 1}: Analyzing logs from service '${step['file_analyzed']}'`;
    } else {
      return `Step ${index + 1}: Analyzing metric '${step['metric_analyzed']}' from service '${step['service_analyzed']}'`;
    }
  };

  return (
    <Card className="my-3 analysis-steps">
      <Card.Body>
        <Card.Title>Step-by-Step Root Cause Analysis</Card.Title>
        {props.analysisData && props.analysisData.analysisSteps.length > 0 && (
        <Accordion alwaysOpen>
          {props.analysisData.analysisSteps.map((step, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
              {getStepTitle(step, index)}
                {/* Step {index + 1}: {step['step_type'] === 'log_analysis' ? 'Log Analysis' : 'Metric Analysis'} */}
              </Accordion.Header>
              <Accordion.Body>
                {step['step_type'] === 'log_analysis' 
                  ? <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />
                  : <MetricAnalysisStepView stepIndx={index + 1} analysisStepData={step} />
                }
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        ) 
        }
        {!props.analysisData.rootCause && (
          <div className="d-flex align-items-center mt-3">
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2">Relvy working its magic....</span>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};


const AnalysisDataView = (props) => {
  return (<div>
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Possible Root Cause</Card.Title>
        {props.analysisData && props.analysisData.rootCause ? (
          <>
            <Card.Text>{props.analysisData.rootCause}</Card.Text>
            {props.analysisData.nextStep && props.analysisData.nextStep !== "root_cause" && (
              <div className="mt-3">
                <h4>Recommended Action</h4>
                <p>{props.analysisData.nextStep}</p>
                <p>{props.analysisData.nextStepReason}</p>
              </div>
            )}
          </>
        ) : (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Card.Body>
    </Card>

    <AnalysisSteps analysisData={props.analysisData} />

  </div>);
};

const MainContent = ({ issue, analysisData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleShowModal = (file) => {
    setSelectedFile(file);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
  };

  // Tutorial Code.
  const [runTour, setRunTour] = useState(false);

  const steps = [
    {
      target: '.description-card',
      content: 'When an incident is reported, Relvy begins the troubleshooting process by analyzing the relevant logs and metrics step-by-step.',
      disableBeacon: true,
    },
    {
      target: '.attachments-card',
      content: 'Relvy integrates across your observability stack to query logs and metrics - be it attachments, aws, gcp, datadog, grafana or others. Here we show the logs and metrics as attachments.',
    },
    {
      target: '.nav-tabs .nav-item:nth-child(2)',
      content: 'Relvy integrates into your collaboration software like Jira, Slack, etc. to show the detailed analysis of the incident right there. Click on the Relvy tab to check it out.',
    },
  ];

  useEffect(() => {
    if (issue?.relevant_files?.length > 0) {
      setRunTour(true);
    }
  }, [issue]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    }
  };

  return (
    <div>
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showSkipButton
        scrollToFirstStep={false}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        locale={{
          'last': 'Go!',
        }}
        styles={{
          options: {
            primaryColor: '#5935c9',
            textColor: '#333',
          },
          tooltip: {
            fontSize: '14px', // Change this value to adjust the content text size
          },
        }}
        floaterProps={{
          disableAnimation: true,
        }}
      />
      <Breadcrumb issue={issue} />
      <h2>{issue?.summary || <CustomPlaceholder xs={10} size="lg" />}</h2>

      <Tabs defaultActiveKey="details" className="mb-3">
        <Tab eventKey="details" title="Details">
          <DetailsTab issue={issue} onShowModal={handleShowModal} />
        </Tab>
        <Tab 
          eventKey="analysis" 
          title="Relvy" 
        >
          <AnalysisTab analysisData={analysisData} />
        </Tab>
      </Tabs>

      <AttachmentModal
        show={showModal}
        onHide={handleCloseModal}
        file={selectedFile}
        fileData={issue.relevant_file_data?.[selectedFile]}
      />
    </div>
  );
};


const IssueDetailsPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const leftSidebarItems = [
    'Back to project',
    'All issues',
    'My open issues',
    'Reported by me',
    'Open issues',
    'Done issues'
  ];

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let queryParams = useQuery();

  const [locator, setLocator] = useState(null);
  const [system_name, setSystemName] = useState(null);
  const [issueData, setIssueData] = useState({
    summary: null,
    details: null,
    relevant_files: null,
  });
  const [analysisData, setAnalysisData] = useState(null);

  const [failureMessage, setFailureMessage] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [analysisIntervalId, setAnalysisIntervalId] = useState(null);
  const [loading, setLoading] = useState(false);

  const processIssueData = (data) => {
    console.log(data);
    setIssueData((prevData) => ({
      ...prevData,
      ...data,
    }));
    processAnalysisData(data['analysis_output']);
  };

  useEffect(() => {
    if (queryParams.get('system_name')) {
      setSystemName(queryParams.get('system_name'));
    }

    if (queryParams.get('locator')) {
      setLocator(queryParams.get('locator'));
    }
  }, [queryParams]);

  const fetchIssueData = async () => {
    if (!locator || !system_name) {
      return;
    }
    /*
    const encodedLocator = encodeURIComponent(locator);
    const encodedSystemName = encodeURIComponent(system_name);
    //const API_BASE_URL = "http://localhost:5000";
    const API_BASE_URL = "https://sandbox.relvy.ai";

    const url = `${API_BASE_URL}/api/sandbox/incidents?locator=${encodedLocator}&system_name=${encodedSystemName}`;
    */
    
    const url = "/api/sandbox/incidents?locator=" + locator + "&system_name=" + system_name;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options);
    if (response.ok) {
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  const processAnalysisData = (data) => {
    var output = {};      
    
    let finalResponse = data["final_response"];
    if ("next_step" in finalResponse) {
      output['nextStep'] = finalResponse["next_step"] === 'request_logs' ? "Request logs from " + finalResponse["params"] : (
        finalResponse["next_step"] === 'request_metrics' ? "Request metrics from " + finalResponse["params"]["service"] + ' - ' + finalResponse["params"]["metric_name"] : finalResponse["next_step"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
    }

    var displayAnalysisSteps = [];
    var displayNextStep = "";
    var displayNextStepReason = "";
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let [analysisStepType, analysisStepData] = data["analysis_history"][stepIndx];
      if (analysisStepType === "next_step") {
        if (analysisStepData["next_step"] === "request_logs") {
          displayNextStep = "Process logs from " + analysisStepData["params"]["service"];
        } else if (analysisStepData["next_step"] === "request_metrics") {
          displayNextStep = "Process metrics from " + analysisStepData["params"]["service"] + ' - ' + analysisStepData["params"]["metric_name"];
        } else {
          displayNextStep = analysisStepData["next_step"];
        }
        displayNextStepReason = analysisStepData["reason"];
      } else if (analysisStepType === "observation"){
        if (analysisStepData["observation_type"] === 'log_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromLogAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else if (analysisStepData["observation_type"] === 'metrics_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromMetricsAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else {
          console.log("Unknown observation type: " + analysisStepData["observation_type"]);
        }
      }
    }

    let skipEmptySteps = true;

    if (skipEmptySteps) {
      var emptyStepsKeptCount = 0;
      displayAnalysisSteps = displayAnalysisSteps.filter((step) => {
        if (step['step_type'] === 'metrics_analysis') {
          if (step['metric'] && step['metric']['points'] && step['metric']['points'].length > 0){
            return true;
          } else {
            if (emptyStepsKeptCount < 1) {
              emptyStepsKeptCount += 1;
              return true;
            }
          }
        } else if (step['step_type'] === 'log_analysis') {
          if (step['observation'] && step['observation']['logLines'] && step['observation']['logLines'].length > 0){
            return true;
          } else {
            if (emptyStepsKeptCount < 1) {
              emptyStepsKeptCount += 1;
              return true;
            }
          }
        }
        return false;
      });
    }

    let dedupSteps = true;
    var stepTitles = new Set();
    if (dedupSteps) {
      displayAnalysisSteps = displayAnalysisSteps.filter((step) => {
        let stepId = step['step'] + '-' + JSON.stringify(step['query']);
        if (stepTitles.has(stepId)) {
          return false;
        }
        stepTitles.add(stepId);
        return true;
      });
    }

    output['analysisSteps'] = displayAnalysisSteps;
    setAnalysisData(output);
  };

  /*
  const fetchAnalysisData = async () => {
    if (!gcpLocation) { return; }
    let gcpLocationEncoded = encodeURIComponent(gcpLocation);
    const url = "http://localhost:5000/api/admin/issue_analysis_viewer?gcp_location=" + gcpLocationEncoded;;

    // API call options
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await fetch(url, options)
    if (response.ok){
      processAnalysisData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };
  */
  const [numTries, setNumTries] = useState(0);

  useEffect(() => {
    fetchIssueData();
    const id = setInterval(() => {
      if (numTries > 10) {
        clearInterval(intervalId);
        setFailureMessage("Failed to fetch data");
      }
      setNumTries(numTries + 1);
      fetchIssueData();
    }, 5000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [locator]);

  /*
  useEffect(() => {
    fetchAnalysisData();
    const aid = setInterval(() => {
      fetchAnalysisData();
    }, 5000);
    setAnalysisIntervalId(aid);

    return () => clearInterval(aid);
  }, [gcpLocation]);
  */

  useEffect(() => {
    if (issueData.relevant_files && issueData.relevant_files.length > 0 && analysisData && analysisData.rootCause) {
      clearInterval(intervalId);
      console.log(issueData);
      console.log(analysisData);
    }
  }, [issueData, analysisData, intervalId]);

  /*
  useEffect(() => {
    if (analysisData && analysisData.rootCause) {
      clearInterval(analysisIntervalId);
    }
  }, [analysisData, analysisIntervalId]);
  */

  return (
    <div class="container">
      <Container fluid className="vh-100 d-flex flex-column">
        <Row className="flex-grow-1">
          <Col md={sidebarOpen ? 6 : 9} lg={sidebarOpen ? 7 : 9} className="p-3">
            <MainContent issue={issueData} analysisData={analysisData}/>
          </Col>
          <Col md={2} lg={2} className="bg-light p-3 border-start">
            <RightSidebar issue={issueData} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IssueDetailsPage;
