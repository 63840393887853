export const createDisplayStepFromMetricsAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason) => {
    let ret = {
      'step_type': 'metrics_analysis',
      'step': displayNextStep,
      'reason': displayNextStepReason,
      'service_analyzed': analysisStepData["service_analyzed"],
      'metric_analyzed': analysisStepData["metric_analyzed"],
      'summary': analysisStepData["summary"],
      'query': build_metric_query(analysisStepData),
      'metric': analysisStepData['data_source_info']['metric']
    };
    return ret;
};

export const build_log_query = (log_source_info) => {
    if (!log_source_info) {
      return null;
    }
    if (log_source_info.log_source_type === 'gcp'){
      let u = 'https://console.cloud.google.com/logs/query;query=' + encodeURIComponent(log_source_info.gcp.query);
      return {
        url: u.replace('(', '%2528').replace(')', '%2529'),
        dataSource: 'GCP',
        rawQuery: log_source_info.gcp.query
      };
    } else if (log_source_info.log_source_type === 'grafana'){
      let url = new URL(log_source_info.grafana.query);
      let rawQuery = url.searchParams.get('panes');
      return {
        url: log_source_info.grafana.query,
        dataSource: 'Grafana',
        rawQuery: rawQuery
      };
    }
    return null;
  };

export const build_metric_query = (analysisStepData) => {
  if ('gcp' in analysisStepData['data_source_info'] && 'query' in analysisStepData['data_source_info']['gcp']) {
    return {
      url: 'https://console.cloud.google.com/monitoring/metrics-explorer?pageState=' + encodeURIComponent(JSON.stringify(
        {"timeSeriesQuery": analysisStepData['data_source_info']['gcp']['query']})),
      rawQuery: analysisStepData['data_source_info']['gcp']['query'],
      dataSource: 'gcp'
    };
  }
  let query = {
    'metric': analysisStepData['metric_analyzed'],
  };
  if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['attributes']){
    query['attributes'] = analysisStepData['data_source_info']['metric']['attributes'];
  }
  if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['start_time']){
    query['start_time'] = analysisStepData['data_source_info']['metric']['start_time'];
  }
  if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['end_time']){
    query['end_time'] = analysisStepData['data_source_info']['metric']['end_time'];
  }
  return {
    url: null,
    rawQuery: JSON.stringify(query, null, 4),
    dataSource: null
  };
};

export const createDisplayStepFromLogAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason) => {
    var rootCauseObservationIndx = -1;
    if (analysisStepData["observations"].length === 0) {
      return {
        'step_type': 'log_analysis',
        'step': displayNextStep,
        'reason': displayNextStepReason,
        'file_analyzed': analysisStepData["service_analyzed"],
        'query': build_log_query(analysisStepData["log_source_info"]),
        'observation': {
          'logLines': [],
          'relevance': "No logs found."
        }
      };
    }
    for (var observationIndx=0; observationIndx < analysisStepData["observations"].length; observationIndx++) {
      let observation = analysisStepData["observations"][observationIndx];
      if (observation["is_root_cause"]) {
        rootCauseObservationIndx = observationIndx;
        break;
      }
    }

    if (rootCauseObservationIndx === -1) {
      for (var observationIndx=0; observationIndx < analysisStepData["observations"].length; observationIndx++) {
        let observation = analysisStepData["observations"][observationIndx];
        if (observation["relevance_to_incident"] && observation["relevance_to_incident"].toLowerCase().includes("high")) {
          rootCauseObservationIndx = observationIndx;
          break;
        }
      }
    }

    // If no high relevance observation, show the first one
    if (rootCauseObservationIndx === -1) {
      rootCauseObservationIndx = 0;
    }

    let logObservation = analysisStepData["observations"][rootCauseObservationIndx]["log_message"];
    let logObservationRelevance = analysisStepData["observations"][rootCauseObservationIndx]["relevance_to_incident"];
    return {
        'step_type': 'log_analysis',
        'step': displayNextStep,
        'reason': displayNextStepReason,
        'file_analyzed': analysisStepData["service_analyzed"],
        'query': build_log_query(analysisStepData["log_source_info"]),
        'observation': {
          'logLines': analysisStepData["log_source_info"]["logs"],
          'highlight': logObservation,
          'relevance': logObservationRelevance
        }
    };
  };
